import { graphql } from 'gatsby'
import Img, { FixedObject } from 'gatsby-image'
import { Link } from 'gatsby'
import React from 'react'
import Meta from 'components/meta/meta'
import Layout from 'components/layout/layout'
import styled from 'styled-components'
import Container from 'components/pages/container'
import ContainerFluid from 'components/pages/container-fluid'
import BackgroundImage from 'gatsby-background-image'
import ButtonBlack from 'components/button/button-black'
import { Helmet } from 'react-helmet'
import OGImage from '../../content/images/logo.png'

interface Props {
  location: Location
}

const DisclaimerPage: React.FC<Props> = ({ data, location }: Props) => {
  const meta = data.site?.meta
  const hero = data.hero?.childImageSharp?.fluid
  const hero_background = data.hero_background?.childImageSharp?.fluid
  const hero_img = data.hero_img?.childImageSharp?.fluid

  return (
    <Layout location={location}>
      <Helmet>
        <meta http-equiv="Content-Type" content="text/html; charset=UTF-8"/>
        <title>Disclaimer Indianapolis IN, endodontics pc</title>
        <meta name="description" content="endodontics pc & Endodontist Adam B. Broady, DDS, MSD in Indianapolis IN offers Endodontics, 317-846-4980" />
        <link rel="canonical" href="https://meridianendoindy.com/disclaimer/" />
        <meta property="og:type" content="article"/>
        <meta property="og:title" content="Disclaimer Indianapolis IN, endodontics pc" />
        <meta name="og:description" content="endodontics pc & Endodontist Adam B. Broady, DDS, MSD in Indianapolis IN offers Endodontics, 317-846-4980" />
        <meta property="og:url" content="https://meridianendoindy.com/disclaimer/" />
        <meta property="og:image" content={OGImage} />
        <meta property="og:image:width" content="279" />
        <meta property="og:image:height" content="65" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content="Disclaimer Indianapolis IN, endodontics pc" />
        <meta name="twitter:description" content="endodontics pc & Endodontist Adam B. Broady, DDS, MSD in Indianapolis IN offers Endodontics, 317-846-4980" />
        <meta name="twitter:url" content="https://meridianendoindy.com/disclaimer/" />
        <meta name="twitter:image" content={OGImage} />
        <meta property="og:image:width" content="279" />
        <meta property="og:image:height" content="65" />
      </Helmet>
      <BackgroundImage
        Tag="section"
        className="disclaimer-section-bg hero-bg"
        fluid={hero_background}
        alt="Endodontics pc"
      >
      <div id="disclaimer-hero-section">
        <Container>
          <div className="col-lg-6 left-content">
            <h1 className="primary-text hero-heading">
              Disclaimer
            </h1>
            <p className="hero-text text-black">
              Privacy Policy for https://meridianendoindy.com/
            </p>
            <p>
              If you require any more information or have any questions about our privacy policy, please feel free to contact us. At https://meridianendoindy.com/, the privacy of our visitors is of extreme importance to us. This privacy policy document outlines the types of personal information that is received and collected by https://meridianendoindy.com/ and how it is used.
            </p>
          </div>
          <div className="col-lg-6">
            <Img fluid={hero_img} className="hero-img" alt="professional lady"/>
          </div>
        </Container>
      </div>
    </BackgroundImage>
  <section id="faded-bg"></section>
  <section id="disclaimer-section-1">
    <Container>
      <div className="col-lg-12">
        <h4> Personal Information </h4>
          <p>
            By entering your full name, email address, and phone number, you are providing personal information that will be used by endodontics pc for the sole purpose of returning your request to be contacted by us. We will only use this information to contact you in order to assist you in scheduling an appointment to be seen by Dr. Broady, and/or to answer any questions you may have indicated in the comments section. Our intention is to only use your personal information to return your request for contact regarding a dental appointment, and/or a dental related question.
          </p>
          <h4> Opt-Out Option </h4>
            <p>
              Please contact us if you wish to opt-out/unsubscribe from receiving any future communication.
            </p>
          <h4> Log Files </h4>
            <p>
              Like many other Web sites, https://www.endo-pc.com makes use of log files. The information inside the log files includes internet protocol ( IP ) addresses, type of browser, Internet Service Provider ( ISP ), date/time stamp, referring/exit pages, and number of clicks to analyze trends, administer the site, track user’s movement around the site, and gather demographic information. IP addresses, and other such information are not linked to any information that is personally identifiable.
            </p>
          <h4> Accessibility </h4>
            <p>
              We strive to make the endodontics pc website universally accessible and we are continuously working to improve the accessibility of content on our website. Below, you’ll find a few recommendations to help make your browsing experience more accessible:
            </p>
            <p>
              If you have trouble seeing web pages, the <a href="https://www.ssa.gov/accessibility/" target="_blank"> <strong>US Social Security Administration offers these tips</strong> </a>for optimizing your computer and browser to improve your online experience.
            </p>
        <ul>
          <li><a href="https://www.ssa.gov/accessibility/keyboard_nav.html" target="_blank">Use the keyboard to navigate screens</a></li>
          <li><a href="https://www.ssa.gov/accessibility/textsize.html" target="_blank">Increase text size </a></li>
          <li><a href="https://www.ssa.gov/accessibility/magnifyscreen.html" target="_blank">Magnify your screen </a></li>
          <li><a href="https://www.ssa.gov/accessibility/changecolors.html" target="_blank">Change background and text colors </a></li>
          <li><a href="https://www.ssa.gov/accessibility/mousepointer.html" target="_blank">Make your mouse pointer more visible (Windows only)</a></li>
       </ul>
          <p>
            If you are looking for mouse and keyboard alternatives, speech recognition software such as <a href="https://www.nuance.com/dragon.html" target="_blank"><strong>Dragon Naturally Speaking</strong> </a>may help you navigate web pages and online services. This software allows the user to move focus around a web page or application screen through voice controls.
          </p>
          <p>
            If you are deaf and or hard of hearing, there are several accessibility features available to you:
          </p>
       <h4> Closed Captioning </h4>
          <p>
            Closed captioning provides a transcript for the audio track of a video presentation that is synchronized with the video and audio tracks. Captions are generally visually displayed over the video, which benefits people who are deaf and hard of hearing, and anyone who cannot hear the audio due to noisy environments. Most of our video content includes captions. Learn how to turn captioning on and off in YouTube.
          </p>
       <h4> Volume Controls </h4>
          <p>
            Your computer, tablet, or mobile device has volume control features. Each video and audio service has its own additional volume controls. Try adjusting both your device’s volume controls and your media players’ volume controls to optimize your listening experience.
          </p>
          <p>
            If the recommendations above do not meet your needs, please contact us at Indianapolis Office Phone Number317-846-4980 for assistance.
          </p>
      </div>
    </Container>
  </section>
    </Layout>
  )
}

export default DisclaimerPage

export const query = graphql`
  query DisclaimerPageQuery {
    site {
      meta: siteMetadata {
        title
        description
        siteUrl
      }
    },
    hero_background: file(name: { eq: "introduction-hero-bg" }) {
      childImageSharp {
        fluid(maxWidth: 1400, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    hero_img: file(name: { eq: "disclaimer-img" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
  }
`
